/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, Redirect, BrowserRouter, useParams } from 'react-router-dom'
import { parseCookies  } from 'nookies'
import './scss/style.scss'





const loading = (
  <React.Fragment>
    {/* <audio src="/epic-dramatic-action-trailer-99525.mp3" autoPlay loop style={{ display: "none" }} /> */}
    {/* <img className="mb-4" src={'https://media4.giphy.com/media/bHSkKRvkRvy5chUBBp/giphy.gif'} alt="Loading..." style={{
      // fullscreen
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // center
      margin: "auto",
      // size
      width: "100%",
      height: "100%",
      // background
      backgroundColor: "black",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",

    }} /> */}
    <h1>Loading</h1>
  </React.Fragment>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


if(window?.location?.href){
  const isDEV = window.location.host === "localhost:3000"
  localStorage.setItem('DEV', isDEV);

  window.FRONTEND_API_URL = isDEV ? "http://localhost:1337" : "https://api.rankmaker.de"
  window.MEDIA_URL = isDEV ? "" : ""
}

class App extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      user:undefined,
    }
  }

  async getUser(){
    const me = await fetch(`${window.FRONTEND_API_URL}/api/users/me?populate=role,img,sip,email_settings,email_settings_outbound,phonesetup,team`, {
      method: "GET",
      headers: {
        "accept": "application/json, text/plain, */*",
        "content-type": "application/json;charset=UTF-8",
        'Authorization': `Bearer ${parseCookies().jwt}`,
        
      },
    })
    console.log("done4")


    const meResponse = await me.json();
    
    window.user = meResponse
    this.setState({user:meResponse})


  }
  
  render() {
    const {user} = this.state
    const cookies = parseCookies()
    let jwt = cookies.jwt

    if (!jwt) {
      return <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<Login />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    }

    if (!user) {
      this.getUser()
      return loading
    }

    if (user.role.name) {
      return (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )
    }

    return <h1>Error beim Login!</h1>

  }
}

export default App


